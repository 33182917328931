// extracted by mini-css-extract-plugin
export var ammount = "shipping-policy-module--ammount--uX0DH";
export var ammount1 = "shipping-policy-module--ammount1--XSQev";
export var bannerSuper = "shipping-policy-module--bannerSuper--54wdP";
export var businessDays = "shipping-policy-module--businessDays--VyKuE";
export var button = "shipping-policy-module--button--FBkCn";
export var callPhoneNumber = "shipping-policy-module--callPhoneNumber--ZfIos";
export var callPhoneNumberOrAlertU = "shipping-policy-module--callPhoneNumberOrAlertU--+Y81o";
export var canadianSupply = "shipping-policy-module--canadianSupply--0jJ9c";
export var caption = "shipping-policy-module--caption--kurqk";
export var caption10 = "shipping-policy-module--caption10--95ID3";
export var caption30 = "shipping-policy-module--caption30--nydTX";
export var caption30a = "shipping-policy-module--caption30a--5aCLW";
export var ccs = "shipping-policy-module--ccs--Spogx";
export var column = "shipping-policy-module--column--ZJvZc";
export var display = "shipping-policy-module--display--K9Utr";
export var display1 = "shipping-policy-module--display1--GWeEy";
export var display4 = "shipping-policy-module--display4---Z1hh";
export var docTemplate = "shipping-policy-module--docTemplate--lnmxl";
export var email = "shipping-policy-module--email--FO33P";
export var footer = "shipping-policy-module--footer--TAYGd";
export var footerContent = "shipping-policy-module--footerContent--MGvfi";
export var footerL = "shipping-policy-module--footerL--6yMnx";
export var footerLinks = "shipping-policy-module--footerLinks--n2lzE";
export var footerR = "shipping-policy-module--footerR--rjfPq";
export var fullNavBar = "shipping-policy-module--fullNavBar--aqBiF";
export var iconButton = "shipping-policy-module--iconButton--ydR78";
export var iconButton1 = "shipping-policy-module--iconButton1--B3o+8";
export var iconFilled = "shipping-policy-module--iconFilled--SyGzQ";
export var infowebsitecom = "shipping-policy-module--infowebsitecom--EawUt";
export var logo = "shipping-policy-module--logo--bWOOn";
export var logo1 = "shipping-policy-module--logo1--tWObm";
export var navbarContent = "shipping-policy-module--navbarContent--fkpPg";
export var navbarContentMobile = "shipping-policy-module--navbarContentMobile--imZs-";
export var navigation = "shipping-policy-module--navigation--v9Yt8";
export var navigation1 = "shipping-policy-module--navigation1--ZkoiI";
export var orderAmount = "shipping-policy-module--orderAmount--rSX9q";
export var p = "shipping-policy-module--p--jLvHk";
export var p1 = "shipping-policy-module--p1--s+mIY";
export var p14 = "shipping-policy-module--p14--rqBRd";
export var p2 = "shipping-policy-module--p2--oKQwE";
export var p20 = "shipping-policy-module--p20--4tIsg";
export var p7 = "shipping-policy-module--p7--6zR4x";
export var privacyPolicy = "shipping-policy-module--privacyPolicy--DdVzS";
export var shippingPolicy = "shipping-policy-module--shippingPolicy--05GwE";
export var span = "shipping-policy-module--span--3vaP6";
export var subtitle2 = "shipping-policy-module--subtitle2--sww4g";
export var subtitleLarge = "shipping-policy-module--subtitleLarge--6sRDb";
export var tcpSupplyLogoIcon = "shipping-policy-module--tcpSupplyLogoIcon--8Bo1y";
export var tcpSupplyLogoIcon2 = "shipping-policy-module--tcpSupplyLogoIcon2--w5NBu";
export var termsAndConditions = "shipping-policy-module--termsAndConditions--p8+A3";
export var textColumn = "shipping-policy-module--textColumn--mDMtW";
export var textbody = "shipping-policy-module--textbody--pLuLu";
export var textbody2 = "shipping-policy-module--textbody2--E74n-";
export var textbody4 = "shipping-policy-module--textbody4--MMIT5";
export var textbody6 = "shipping-policy-module--textbody6--BKcuF";
export var title = "shipping-policy-module--title--9H48J";
export var visaIcon = "shipping-policy-module--visaIcon--lQ8zJ";