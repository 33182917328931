import * as React from "react";
import { FunctionComponent, useState, useCallback } from "react";
import { Button, IconButton, Link } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { navigate } from "gatsby";
import NavigationMobile from "../components/navigation-mobile";
import PortalDrawer from "../components/portal-drawer";
import * as styles from "./shipping-policy.module.css";
import Layout from "../components/Base/Layout";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const ShippingPolicy: FunctionComponent = () => {
  const [isNavigationMobileOpen, setNavigationMobileOpen] = useState(false);

  const onButton1Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton2Click = useCallback(() => {
    const anchor = document.querySelector(
      "[data-scroll-to='contactSectionContainer']"
    );
    if (anchor) {
      anchor.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, []);

  return (
    <>
    <Layout>
      <div className={styles.shippingPolicy}>
        <div className={styles.docTemplate}>
          <div className={styles.textColumn}>
            <div className={styles.title}>
              <b className={styles.display}>Shipping Policy</b>
            </div>
            <div className={styles.p}>
              <div className={styles.caption}>
                Thank you for visiting and shopping at TCP Supply.
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>Domestic Shipping Policy</div>
            </div>
            <div className={styles.p}>
              <div className={styles.caption}>
              Shipment processing time.
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  All orders are processed within 2–3 business days of receipt.
                  Orders are not processed or shipped from Friday, 12 pm EST
                  through Sunday, or holidays.
                </div>
              </div>
              <div className={styles.p2}>
                <div className={styles.caption}>
                  If we are experiencing a high volume of orders, shipments may
                  be delayed by a few days. If your shipment experiences a
                  significant delay, we will contact you via email or phone.
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  Shipping rates and delivery estimates
                </div>
              </div>
              <div className={styles.p2}>
                <div className={styles.caption}>
                  Shipping charges for your order will be calculated and
                  displayed at checkout.
                </div>
              </div>
            </div>
            <div className={styles.textbody2}>
              <div className={styles.ammount1}>
                <div className={styles.p1}>
                  <b className={styles.caption}>
                    <p className={styles.orderAmount}>Order Amount</p>
                  </b>
                </div>
                <div className={styles.p1}>
                  <b className={styles.caption}>$0.00-250</b>
                </div>
                <div className={styles.p7}>
                  <b className={styles.caption}>$250+</b>
                </div>
              </div>
              <div className={styles.ammount}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    <p className={styles.orderAmount}>
                      <b>Standard Ground Shipping</b>
                    </p>
                    <p className={styles.businessDays}>
                      <span>(1–7 business days)</span>
                    </p>
                  </div>
                </div>
                <div className={styles.p1}>
                  <b className={styles.caption}>$25.00</b>
                </div>
                <b className={styles.caption10}>*Free</b>
              </div>
              <div className={styles.ammount}>
                <div className={styles.p1}>
                  <div className={styles.caption}>
                    <p className={styles.orderAmount}>
                      <b>FedEx Ground</b>
                    </p>
                    <p className={styles.businessDays}>
                      <span>(3–7 business days)</span>
                    </p>
                  </div>
                </div>
                <div className={styles.p1}>
                  <b className={styles.caption}>$50.00</b>
                </div>
                <div className={styles.p7}>
                  <b className={styles.caption}>*Free</b>
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  <p className={styles.orderAmount}>
                    TCP Supply ships to addresses within the US, US Territories,
                    and APO/FPO/DPO addresses.
                  </p>
                  <p className={styles.orderAmount}>&nbsp;</p>
                  <p className={styles.businessDays}>
                    Shipment Confirmation and Order Tracking
                  </p>
                </div>
              </div>
              <div className={styles.p14}>
                <div className={styles.caption}>
                  You will receive a Shipment Confirmation email with your
                  tracking number once your order has shipped. The tracking
                  number will be active within 24 hours.
                </div>
              </div>
              <div className={styles.p}>
                <div className={styles.caption}>Customs, Duties, and Taxes</div>
              </div>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  TCP Supply is not responsible for any customs and taxes
                  applied to your order. All fees imposed during or after
                  shipping are the customer’s responsibility (including tariffs,
                  taxes, and other costs).
                </div>
              </div>
            </div>
            <div className={styles.p}>
              <div className={styles.caption}>Damages</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  TCP Supply is not liable for any products damaged or lost
                  during shipping. If you received your order damaged, please
                  file a claim with the shipment carrier. Save all packaging
                  materials and damaged goods before filing a claim.
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>
                Incorrect Shipping Addresses and Refused Delivery
              </div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  <p className={styles.orderAmount}>
                    We make every attempt to validate the shipping address
                    provided at checkout to ensure it’s recognized as a valid
                    address by the USPS. If we cannot validate the address, we
                    will try to contact the customer to provide an updated
                    address. If we cannot update the address, the order will be
                    canceled and refunded.
                  </p>
                  <p className={styles.orderAmount}>&nbsp;</p>
                  <p className={styles.businessDays}>
                    TCP Supply will not be held responsible if the customer
                    provides the wrong shipping address and we cannot recover
                    the package.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>Missing or Stolen Shipments</div>
            </div>
            <div className={styles.textbody6}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  <p className={styles.orderAmount}>
                    If you didn’t receive your order, but the shipping carrier
                    has reported that it was delivered, please let us know as
                    soon as possible:
                  </p>
                  <p className={styles.orderAmount}>&nbsp;</p>
                  <ol className={styles.callPhoneNumberOrAlertU}>
                    <li className={styles.callPhoneNumber}>
                      Call <Link href="tel:18885741440" color="#267F88">+1-888-574-1440</Link>
                    </li>
                    <li className={styles.callPhoneNumber}>
                      Or alert us at <Link href="mailto:info@tcpsupply.net" color="#267F88">info@tcpsupply.net</Link>
                    </li>
                  </ol>
                  <p className={styles.orderAmount}>&nbsp;</p>
                  <p className={styles.businessDays}>
                    We will file a claim with the shipping carrier. Local law
                    enforcement will be involved. We will replace or refund your
                    order when the investigation is complete. Allow up to 21
                    days for the investigation.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className={styles.textbody}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  These organizations may link to our home page, publications or
                  other Website information so long as the link:
                </div>
              </div>
              <div className={styles.p20}>
                <div className={styles.caption}>
                  <ul className={styles.callPhoneNumberOrAlertU}>
                    <li className={styles.callPhoneNumber}>
                      Not in any way deceptive.
                    </li>
                    <li className={styles.callPhoneNumber}>
                      Not falsely imply sponsorship, endorsement or approval of
                      the linking party and its products and/or services.
                    </li>
                    <li>
                      It fits within the context of the linking party’s site.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  We only approve link requests from these organizations if it
                  is proven that
                </div>
              </div>
              <div className={styles.p20}>
                <div className={styles.caption}>
                  <ul className={styles.callPhoneNumberOrAlertU}>
                    <li className={styles.callPhoneNumber}>
                      the link would not make us look unfavorably at ourselves
                      or our accredited businesses
                    </li>
                    <li className={styles.callPhoneNumber}>
                      the organization does not have any negative records with
                      us
                    </li>
                    <li className={styles.callPhoneNumber}>
                      the benefit to us from the visibility of the hyperlink
                      compensates for the absence of TCP Supply
                    </li>
                    <li>
                      the link is in the context of general resource information
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.p2}>
                <div className={styles.caption}>
                  TCP Supply’s logo or other artwork will not be allowed
                  for usage if linking is absent, and the process is not in
                  accordance with the license agreement.
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>Links to Other Websites</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  Our Service may contain links to third-party websites or
                  services that are not owned or controlled by TCP
                  Supply.
                </div>
              </div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  TCP Supply has no control over and assumes no
                  responsibility for any third-party websites or services'
                  content, privacy policies, or practices. You further
                  acknowledge and agree that TCP Supply shall not be
                  responsible or liable, directly or indirectly, for any damage
                  or loss caused or alleged to be caused by or in connection
                  with the use of or reliance on any such content, goods, or
                  services available on or through any such web sites or
                  services.
                </div>
              </div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  We strongly advise you to read the terms and conditions and
                  privacy policies of any third-party websites or services that
                  you visit.
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>Termination</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  We may terminate or suspend your access immediately, without
                  prior notice or liability, for any reason whatsoever,
                  including without limitation if you breach these Terms and
                  Conditions.
                </div>
              </div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  Upon termination, your right to use the Service will cease
                  immediately. Note that it is our right to allow any user
                  access to our website. This could mean revoking your key at
                  any time without notice. If necessary, we will block your IP
                  address to prevent further visits to our website and use of
                  our Service.
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>Your Privacy</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption30}>
                  <span>{`Please read `}</span>
                  <b className={styles.privacyPolicy}>
                    <a className={styles.caption30a} href="/privacy-policy">Privacy Policy</a>
                    <span className={styles.span}>.</span>
                  </b>
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>Reservation of Rights</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  We reserve the right to remove all links to our website. As
                  you read this document, you approve of executing the action
                  upon our request immediately.
                </div>
              </div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  We also reserve the right to change these terms and
                  conditions, as well as its linking policies. If a revision is
                  necessary, we will notify you of the changes at least 30 days
                  prior to any new terms taking effect.
                </div>
              </div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  By continuing to access or use Our Service after those
                  revisions become effective, you agree to be bound by the
                  revised terms. If You do not agree to the new terms, in whole
                  or part, please stop using the website and the Service.
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>Disclaimer</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  To the maximum extent permitted under applicable law, TCP Supply, on its own behalf and on behalf of its Affiliates
                  and its and their respective licensors and service providers,
                  expressly disclaims all warranties, whether express, implied,
                  statutory or otherwise, with respect to the Service, including
                  all implied warranties of merchantability, fitness for a
                  particular purpose, title and non-infringement, and warranties
                  that may arise out of the course of dealing, course of
                  performance, usage or trade practice.
                </div>
              </div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  Without limiting the preceding, neither TCP Supply nor
                  any of the company's providers makes any representation or
                  warranty of any kind, express or implied:
                </div>
              </div>
            </div>
            <div className={styles.textbody6}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  <ul className={styles.callPhoneNumberOrAlertU}>
                    <li className={styles.callPhoneNumber}>
                      As to the operation or availability of the Service, or the
                      information, content, and materials or products included
                      thereon
                    </li>
                    <li className={styles.callPhoneNumber}>
                      That the Service will be uninterrupted or error-free
                    </li>
                    <li className={styles.callPhoneNumber}>
                      As to the accuracy, reliability, or currency of any
                      information or content provided through the Service
                    </li>
                    <li>
                      The Service, its servers, the content, or e-mails sent
                      from or on behalf of the Company are free of viruses,
                      scripts, trojan horses, worms, malware, timebombs or other
                      harmful components.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>Consent</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  You hereby consent to our Terms and Conditions of Use by using
                  our website.
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>Contact Us</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  <span>
                    <span>
                      If you have any questions about these Terms and
                      Conditions, you can
                    </span>
                    <b className={styles.privacyPolicy}>{` `}</b>
                  </span>
                  <b className={styles.privacyPolicy}>
                    <span className={styles.caption30a}><AnchorLink to="/#contactSectionContainer">contact us</AnchorLink></span>
                    <span className={styles.span}>.</span>
                  </b>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      </Layout>
    </>
  );
};

export default ShippingPolicy;
